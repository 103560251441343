.richtext {
  li,
  p,
  button {
    @include typography(copy);
  }

  p.small {
    @include typography(copy-small);
  }

  p.intro {
    font-weight: 600;
  }

  b,
  strong {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  h2 {
    @include typography(headline-4);
  }

  h3 {
    @include typography(headline-5);
  }

  h4 {
    @include typography(headline-6);
  }

  h2,
  h3,
  h4 {
    margin-bottom: 0;
    margin-top: space(m);

    @include mq(m) {
      margin-top: space(l);
    }
  }

  p {
    margin-top: space(s);
    margin-bottom: 0;
  }

  blockquote {
    position: relative;
    margin: space(l) 0 0;
    padding: 0;
    @include typography(quote-small);

    &:before,
    &:after {
      position: absolute;
      font-size: 96px;
      line-height: 1;
      font-weight: 600;
      color: color(primary);
    }

    &:before {
      content: '”';
      top: 0;
      left: -50px;
    }

    &:after {
      content: '“';
      bottom: -60px;
      right: -12px;
    }
  }

  p.author {
    color: color(primary);
    text-decoration: none;
    margin-bottom: space(m);
    @include typography(copy-small);
  }

  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    margin-top: space(m);
    margin-bottom: space(m);
    padding: 0;
    padding-left: 16px;
    list-style-position: outside;

    li {
      margin-bottom: space(xs);

      &::marker {
        color: color(neutral);
        font-weight: 600;
      }

      p {
        display: inline;
      }
    }
  }

  button + button {
    margin-left: space(m);
  }

  a,
  button {
    border-bottom: 1px solid color(primary);
    text-decoration: none;

    &:hover,
    &:focus {
      color: color(primary);
    }

    &[target='_blank'] {
      margin-left: 15px;

      @include mq(m) {
        margin-left: 25px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: -15px;
        width: 10px;
        height: 11px;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.986.988 4 .924V1.95L18.5 2 .364 20.276l.707.723L18.986 3v14H20L19.984.988h-.998Z' fill='%233885A6'/%3E%3C/svg%3E");
        background-size: 10px 11px;
        transition: transform 0.2s ease-in-out;
        transform: scale(0.9);

        [data-theme='emotional'] & {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.986.988 4 .924V1.95L18.5 2 .364 20.276l.707.723L18.986 3v14H20L19.984.988h-.998Z' fill='%23ff7368'/%3E%3C/svg%3E");
        }

        @include mq(m) {
          width: 20px;
          height: 21px;
          background-size: 20px 21px;
          left: -25px;
        }

        @include mq(l) {
          top: 4px;
        }
      }

      &:hover,
      &:focus {
        &:before {
          transform: translateX(2px) translateY(-2px);
        }
      }
    }
  }

  span[data-glossary-id] {
    color: color(primary);
    cursor: pointer;
    position: relative;
    transition: color 0.2s ease-in-out;

    span {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='43' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 20'%3E%3Cpath fill='%233885a6' d='M13.5 7.8c-1.3 0-2.4 1-2.4 2.4 0 1.5 1.1 2.5 2.4 2.5 1.3 0 2.5-.9 2.5-2.5s-1.3-2.4-2.5-2.4z' /%3E%3Cpath fill='%233885a6' d='M33 0H10C4.5 0 0 4.5 0 10s4.5 10 10 10h23c5.5 0 10-4.5 10-10S38.5 0 33 0zM17.4 14H16v-1.2c-.5.8-1.6 1.3-2.6 1.3-2 0-3.8-1.5-3.8-3.9s1.7-3.9 3.8-3.9c1 0 2.1.4 2.6 1.3V6.5h1.4V14zm7.9-3h-5.6V9.6h5.6V11zm8.1-3.3-4.5 4.9h4.5V14h-6.6v-1.2l4.4-4.9H27V6.5h6.3v1.2z' /%3E%3C/svg%3E");
      background-size: 43px 20px;
      width: 43px;
      height: 20px;
      display: inline-block;
      position: relative;
    }

    [data-theme='emotional'] & {
      color: color(primary, 70);

      span {
        background-image: url("data:image/svg+xml,%3Csvg width='43' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 20'%3E%3Cpath fill='%23ff7368' d='M13.5 7.8c-1.3 0-2.4 1-2.4 2.4 0 1.5 1.1 2.5 2.4 2.5 1.3 0 2.5-.9 2.5-2.5s-1.3-2.4-2.5-2.4z' /%3E%3Cpath fill='%23ff7368' d='M33 0H10C4.5 0 0 4.5 0 10s4.5 10 10 10h23c5.5 0 10-4.5 10-10S38.5 0 33 0zM17.4 14H16v-1.2c-.5.8-1.6 1.3-2.6 1.3-2 0-3.8-1.5-3.8-3.9s1.7-3.9 3.8-3.9c1 0 2.1.4 2.6 1.3V6.5h1.4V14zm7.9-3h-5.6V9.6h5.6V11zm8.1-3.3-4.5 4.9h4.5V14h-6.6v-1.2l4.4-4.9H27V6.5h6.3v1.2z' /%3E%3C/svg%3E");
      }
    }

    &:hover,
    &:focus {
      color: color(primary, 70);
    }
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  [data-alignment='center'] & {
    ul,
    ol {
      @include mq(m) {
        padding-left: 0;
      }
    }
  }
}
