@keyframes page-line-animation {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@mixin get-page-line {
  position: relative;
  --duration: 1.4s;

  & > * {
    position: relative;
  }

  &:before {
    width: 1px;
    background: color(neutral, 30);
    top: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
    z-index: 0;
    content: '';
    margin-left: space(s);
    transform: scaleY(0);
    animation: page-line-animation var(--duration) linear forwards;
    animation-play-state: paused;
    transform-origin: top center;

    @include mq(m) {
      margin-left: 0;
    }

    @each $breakpoint in map-keys($gridConfigs) {
      $breakpointMap: map-get($gridConfigs, $breakpoint);
      $columnGap: map-get($breakpointMap, 'outerGap');

      @if $columnGap == null {
        $columnGap: map-get($breakpointMap, 'columnGap');
      }

      @if $breakpoint == 'default' {
        left: $columnGap;
      } @else {
        @include mq($breakpoint) {
          left: $columnGap;
        }
      }
    }
  }

  &[data-animated='true']:before {
    animation-play-state: running;
  }

  &[data-animated='false']:before {
    animation-duration: 0s;
    animation-play-state: running;
  }

  [data-alignment='center'] &:before {
    @include mq(m) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@mixin get-page-line-top {
  padding-top: space(l);

  @include mq(m) {
    padding-top: space(xxl);
  }
}

@mixin get-page-line-bottom {
  padding-bottom: space(l);

  @include mq(m) {
    padding-bottom: space(xxl);
  }
}

@mixin get-page-line-top-gap {
  padding-top: space(s);

  @include mq(m) {
    padding-top: 0;
  }

  [data-alignment='center'] & {
    padding-top: space(m);
  }
}

@mixin get-page-line-bottom-gap {
  padding-bottom: space(s);

  @include mq(m) {
    padding-bottom: 0;
  }

  [data-alignment='center'] & {
    padding-bottom: space(m);
  }
}

@mixin reset-page-line-gaps {
  &.has-page-line > *,
  &.has-page-line-top > *,
  &.has-page-line-bottom > * {
    padding-top: 0;
    padding-bottom: 0;

    @include mq(m) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.has-page-line,
.has-page-line-top,
.has-page-line-bottom {
  @include grid-container;
  @include get-grid-outer-gaps;
  @include get-page-line;
}

.has-page-line {
  @include get-page-line-top;
  @include get-page-line-bottom;

  & > * {
    @include get-page-line-top-gap;
    @include get-page-line-bottom-gap;
  }
}

.has-page-line-top {
  @include get-page-line-top;

  & > * {
    @include get-page-line-top-gap;
  }
}

.has-page-line-bottom {
  @include get-page-line-bottom;

  & > * {
    @include get-page-line-bottom-gap;
  }
}
