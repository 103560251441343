.bubble-box {
  overflow: hidden;

  &__wrapper {
    background: color(neutral, 10);
    padding-bottom: space(m);
  }

  &__author,
  &__logo {
    margin-bottom: space(m);

    @include mq(l) {
      left: calc(50% - 100px);
      z-index: 2;
      position: relative;
      margin-bottom: -100px;
    }
  }

  &__logo {
    background-color: color(surface);
    border-radius: 200px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(m) {
      width: 200px;
      height: 200px;
    }

    img {
      width: 75%;
      margin: 0 auto;
    }
  }

  &__list {
    position: relative;
    z-index: 1;
    align-items: start;
    justify-content: start;

    @include mq(l) {
      background: color(neutral, 10);
    }

    &:before {
      @include mq(l) {
        content: '';
        position: absolute;
        left: -100vw;
        right: 0;
        bottom: 0;
        background: color(surface);
        height: 65%;
        width: 200vw;
        z-index: -1;
      }
    }

    @include get-grid(
      (
        s: 1,
        l: 2
      ),
      false
    );
  }

  &__item {
    padding-top: space(s);
    padding-bottom: space(s);
    background: color(neutral, 10);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -100vw;
      right: 0;
      top: calc(20% + #{space(xs)});
      background: color(surface);
      height: 80%;
      width: 200vw;

      @include mq(l) {
        display: none;
      }
    }

    @include mq(l) {
      background: none;
    }

    @include get-grid(
      (
        s: 1,
        l: 6
      ),
      false
    );

    @include mq(l) {
      padding-top: space(m);
      padding-bottom: space(m);
    }
  }

  &__container {
    position: relative;
    width: 100%;
    grid-column: 1 / -1;
    margin-top: space(xs);

    @include mq(l) {
      width: calc(100% + #{space(m)});
      transform: translateX(#{space(l)});
    }
  }

  &__topline {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: translateY(50%) translateX(calc(#{space(m)} * -1));
    transition: transform 0.4s 0.8s ease-in-out;

    span {
      background: color(tertiary);
      color: color(on-tertiary);
      padding: space(xxs) space(xs);
      border-radius: space(m);
      transform: translateY(-50%);
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s 1s ease-in-out;
      height: 27px;

      @include mq(l) {
        padding: 0 space(s);
      }
    }
  }

  &__content {
    position: relative;
    margin-top: space(m);
    grid-column: 1 / -1;
    opacity: 0;
    transition: all 0.4s 1.2s ease-in-out;
    color: color(tertiary);

    > * {
      position: relative;
      z-index: 1;
    }

    @include mq(l) {
      margin-top: space(l);
      grid-column: 2 / -1;
    }
  }

  &__circle {
    position: relative;
    padding: space(s) space(m);
    opacity: 0;
    color: color(on-primary);
    display: flex;
    align-items: center;
    background: color(primary);
    border-radius: 9999px;
    transition: all 0.4s 0.8s ease-in-out, opacity 0.2s ease-in-out;
    min-height: 130px;

    h2 {
      position: relative;
      z-index: 1;
      opacity: 0;
      transition: all 0.4s 1.1s ease-in-out;
    }

    @include mq(l) {
      min-height: 255px;
      padding: space(m) space(xl);
    }
  }

  &__item:nth-of-type(odd) &__container {
    z-index: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__item:nth-of-type(even) {
    margin-top: space(m);

    @include mq(l) {
      margin-top: space(xl);
    }

    .bubble-box__container {
      @include mq(l) {
        transform: translateX(calc(#{space(l)} * -1));
      }
    }

    .bubble-box__content {
      @include mq(l) {
        grid-column: 1 / -2;
        transition: all 0.4s 2s ease-in-out;
      }
    }

    .bubble-box__circle {
      background: color(secondary);
      color: color(on-secondary);

      @include mq(l) {
        transition: all 0.4s 1.6s ease-in-out, opacity 0.2s ease-in-out;
        padding-left: calc(#{space(xxl)} + #{space(m)});
      }

      h2 {
        @include mq(l) {
          transition: all 0.4s 1.9s ease-in-out;
        }
      }
    }

    .bubble-box__topline {
      transform: translateY(50%) translateX(#{space(m)});

      @include mq(l) {
        transition: transform 0.4s 1.6s ease-in-out;
      }

      span {
        top: 0;
        right: 0;
        left: auto;

        @include mq(l) {
          transition: transform 0.4s 1.8s ease-in-out;
        }
      }
    }
  }

  // stylelint-disable
  &__item[data-animated='true'],
  &__item:nth-of-type(even)[data-animated='true'] {
    h2 {
      opacity: 1;
      transform: translateX(0);
    }

    .bubble-box__content {
      opacity: 1;
    }
  }

  &__item[data-animated='true'] .bubble-box__topline {
    transform: translateY(0%) translateX(calc(#{space(s)} + #{space(m)}));

    @include mq(l) {
      transform: translateY(0%) translateX(calc(#{space(xl)} + #{space(s)}));
    }
  }

  &__item:nth-of-type(even)[data-animated='true'] .bubble-box__topline {
    transform: translateY(0%) translateX(calc(#{space(s)} + #{space(m)}));

    span {
      left: 0;
      right: auto;
    }

    @include mq(l) {
      transform: translateY(0%) translateX(calc(#{space(xxl)} * -1));

      span {
        left: auto;
        right: 0;
      }
    }
  }
}
