.gallery {
  position: relative;
  overflow: hidden;
  padding: space(m) 0;

  &__wrapper {
    background: color(neutral, 10);
    position: relative;
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 5 / -1;
    }
  }

  h3 {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 3 / -1;
    }
  }

  &__captions {
    grid-column: 1 / -1;
    background: color(neutral, 10);
    padding-bottom: space(m);

    @include mq(m) {
      grid-column: 5 / -1;
    }
  }

  &__caption {
    display: none;
    padding-top: space(xs);

    &[aria-current='true'] {
      display: block;
    }

    span {
      color: color(neutral, 30);
    }
  }

  &__container {
    position: relative;
  }

  &__images {
    position: relative;
    cursor: grab;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    user-select: none;
    pointer-events: none;
    opacity: 0;

    img {
      width: auto;
      height: 100%;
      user-select: none;
      object-fit: contain;
      border-radius: $borderRadius;
      margin: 0 auto;
    }

    &[aria-current='true'] {
      z-index: 3;
      opacity: 1;
      transform: none;
    }
  }

  &__prev {
    left: -20px;

    @include mq(m) {
      left: -34px;
    }
  }

  &__next {
    right: -20px;

    @include mq(m) {
      right: -34px;
    }
  }

  &__prev,
  &__next {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(secondary);
    color: color(on-secondary);
    border-radius: space(xxl);
    position: absolute;
    top: 50%;
    height: 40px;
    width: 40px;
    transform: translateY(-50%);
    z-index: 99;
    @include box-shadow;

    &:hover,
    &:focus {
      background: color(secondary, 70);
      border-color: color(secondary, 70);
    }

    &:active {
      background: color(secondary, 90);
      border-color: color(secondary, 90);
    }

    @include mq(m) {
      height: 68px;
      width: 68px;
    }
  }

  [data-alignment='center'] &__wrapper,
  [data-alignment='center'] & h3,
  [data-alignment='center'] &__captions {
    @include mq(m) {
      grid-column: 3 / -3;
    }
  }
}
