.accordion {
  &__wrapper {
    @include grid-container;

    padding-top: space(m);
    padding-bottom: space(m);

    @include mq(m) {
      padding-top: space(l);
      padding-bottom: space(l);
    }
  }

  &:not(.has-page-line) &__wrapper {
    @include get-grid-outer-gaps;
  }

  &__grid {
    position: relative;
    background: color(neutral, 10);

    @include get-grid((), false);

    @include mq(m) {
      background: none;
    }
  }

  dl {
    grid-column: 1 / -1;
    margin: 0;
    background: color(neutral, 10);
    border-top: 1px solid color(neutral, 50);

    @include mq(m) {
      grid-column: 5 / -1;
    }

    & > div {
      border-bottom: 1px solid color(neutral, 50);
    }
  }

  &__headline {
    grid-column: 1 / -1;
    margin-bottom: space(s);
    position: relative;

    @include mq(m) {
      margin-bottom: space(l);
      grid-column: 5 / -2;
    }
  }

  &__button {
    appearance: none;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    outline: none;
    padding: space(s) 0;
    text-align: left;
    align-items: center;
    transform-style: preserve-3d;
    width: 100%;
    min-height: 100px;

    svg {
      transform: rotateX(180deg);
      transition: transform 0.6s ease-in-out, color 0.2s ease-in-out;
    }

    &:hover svg {
      color: color(primary);
    }

    &[aria-expanded='false'] svg {
      transform: rotateX(0deg);
    }
  }

  &__panel {
    margin: 0;
    padding: 0 0 space(m);
  }

  [data-alignment='center'] & {
    .accordion__grid {
      @include mq(m) {
        background: color(neutral, 10);
        padding-top: space(m);
        padding-bottom: space(m);
      }
    }

    .accordion__wrapper {
      @include mq(m) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .accordion__headline {
      @include mq(m) {
        margin-bottom: space(m);
      }
    }

    .accordion__headline,
    dl {
      @include mq(m) {
        grid-column: 3 / -3;
      }
    }
  }
}
