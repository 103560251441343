.text {
  font-family: $font-family-default;
  color: inherit;
  font-weight: 400;

  @each $name, $value in $typography {
    // Loop over typography keys and build text type
    &--#{$name} {
      @include typography(#{$name});
    }
  }

  &--bold {
    font-weight: 600;
  }

  &--normal {
    font-weight: 400;
  }

  &--italic {
    font-style: italic;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}
