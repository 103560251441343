.radio {
  &__label {
    margin-bottom: space(xs);
    display: block;
  }

  &--hidden-label &__label {
    @include hide-visually();
  }

  input {
    @include hide-visually();
  }

  &--success &__label {
    color: color(success);
  }

  &__item-custom-icon {
    position: relative;
    border: 1px solid color(neutral, 30);
    border-radius: 100%;
    display: inline-block;
    margin-right: space(xs);
    height: 30px;
    width: 30px;

    &:after {
      background: color(neutral);
      border-radius: 100%;
      content: '';
      display: none;
      height: space(xs);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: space(xs);
    }
  }

  &--success &__item-custom-icon {
    border-color: color(success);
  }

  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: 1;
    margin-bottom: space(s);
    position: relative;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  input:checked ~ &__item-custom-icon {
    &:after {
      display: block;
    }
  }

  input:focus ~ &__item-custom-icon {
    outline: 2px solid color(primary);
    outline-offset: 2px;
  }

  &__error.text {
    margin-top: space(s);
    color: color(warning);
    display: none;
  }

  &.form-error &__error {
    display: block;
  }

  &.form-error &__label {
    color: color(warning);
  }

  &.form-error &__item-custom-icon {
    border-color: color(warning);
  }
}
