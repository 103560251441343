.footer {
  background: color(tertiary);
  color: color(on-tertiary);
  padding: 0;

  @include mq(m) {
    padding: space(xl) 0;
  }

  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: color(neutral, 10);
    flex-wrap: wrap;
  }

  &__grid {
    @include grid-container;
    @include get-grid;

    align-items: center;

    @include mq(m) {
      align-items: flex-start;
      grid-row-gap: space(m);
    }
  }

  &__links {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 1 / span 9;
    }

    @include mq(xl) {
      margin-top: space(xs);
      grid-column: 1 / span 5;
    }

    li {
      margin-right: space(xs);
      margin-bottom: space(xs);

      @include mq(m) {
        margin-right: space(s);
      }
    }
  }

  &__languages {
    grid-column: 1 / -1;
    grid-row: 1;

    @include mq(m) {
      grid-column: 10 / span 3;
      justify-self: flex-end;
    }

    @include mq(xl) {
      grid-column: 6 / span 2;
      margin: space(xs) 0 0;
      justify-self: center;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        text-transform: uppercase;
      }

      & > li + li {
        margin-left: space(xs);

        @include mq(m) {
          margin-left: space(s);
        }
      }
    }

    a {
      color: color(tertiary, 20);
      transition: color 0.2s ease-in-out;

      &[aria-current='true'] {
        color: color(neutral, 10);
        pointer-events: none;
      }
    }

    &-divider {
      width: 1px;
      height: 11px;
      display: block;
      background: color(neutral, 30);
      border-radius: 2px;
      margin-left: space(xs);

      @include mq(m) {
        margin-left: space(s);
      }
    }
  }

  &__social-links {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 1 / span 6;
    }

    @include mq(xl) {
      grid-column: 8 / span 3;
    }

    ul {
      @include mq(xl) {
        justify-content: flex-end;
      }

      // stylelint-disable
      li {
        margin-bottom: space(xs);

        @include mq(m) {
          margin-bottom: space(s);
        }
      }

      & > li + li {
        margin-left: space(xs);

        @include mq(m) {
          margin-left: space(s);
        }
      }
    }
  }

  &__social-links,
  &__links {
    border-top: 0.5px dashed rgba(255, 255, 255, 0.5);
    padding-top: space(s);
    padding-bottom: space(s);

    @include mq(m) {
      padding-top: 0;
      padding-bottom: 0;
      border-top: none;
    }
  }

  &__logo {
    grid-column: 1 / -1;
    grid-row: 1;
    justify-self: flex-end;
    padding-top: space(s);
    padding-bottom: space(s);
    color: color(neutral, 10);

    svg {
      width: 90px;

      @include mq(m) {
        width: 130px;
      }
    }

    @include mq(m) {
      padding-top: 0;
      padding-bottom: 0;
      grid-column: 7 / span 6;
      grid-row: auto;
    }

    @include mq(xl) {
      grid-column: 11 / span 2;
    }
  }

  ul a {
    &:hover,
    &:focus {
      color: color(tertiary, 20);
    }
  }

  &__languages ul a:not([aria-current]) {
    &:hover,
    &:focus {
      color: color(neutral, 10);
    }
  }
}
