.iframe {
  background: color(surface);

  &__grid {
    padding-top: space(xl);
    padding-bottom: space(xl);
  }

  h2 {
    margin: 0 0 space(s);
  }

  &__wrapper {
    grid-column: 1 / -1;
    background: color(surface);
    padding: space(m) 0;

    & > div {
      background: color(neutral, 30);
    }
  }
}
