.navigation {
  &__trigger-wrapper {
    position: fixed;
    height: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
  }

  &__trigger-inner {
    @include grid-container;
    @include get-grid-outer-gaps;

    display: flex;
    justify-content: space-between;
  }

  &__logo {
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    background: color(surface);
    padding: space(xxs) space(s);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s ease-in-out;
    transform: translateY(0%);
    margin-right: calc(#{space(s)} * -1);

    @include mq(l) {
      transform: translateY(0%) translateX(100%);
    }

    &[aria-hidden='true'] {
      transform: translateY(-100%);

      @include mq(l) {
        transform: translateY(-100%) translateX(100%);
      }
    }

    svg {
      width: 32px;
    }
  }

  &__trigger {
    background: color(tertiary);
    color: color(on-tertiary);
    height: 45px;
    width: 45px;
    border-top-right-radius: 68px;
    border-bottom-right-radius: 68px;
    margin-top: space(s);
    position: relative;
    left: 0px;
    transform-origin: top center;
    transition: all 0.4s ease-in-out;
    transform: translateY(-200%);

    &:before {
      position: absolute;
      content: '';
      background: color(tertiary);
      top: 0;
      left: -100px;
      width: 101px;
      height: 45px;
      transition: all 0.4s ease-in-out;

      @include mq(m) {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &:focus:before,
    &:hover:before {
      background: color(tertiary, 70);
    }

    &:active,
    &:active:before {
      background: color(tertiary, 90);
    }

    svg {
      margin-right: 3px;

      @include mq(m) {
        margin-right: 0;
      }
    }

    @include mq(m) {
      transform: scale(0);
      width: 136px;
      height: 68px;
      margin-top: 0;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 68px;
      border-bottom-right-radius: 68px;
      top: -10px;
      left: -68px;
    }
  }

  &__overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: translateY(-100%);
    display: none;
    background: color(surface);

    .main-stage {
      height: 100vh;
      overflow-y: scroll;
      background: transparent;
      position: relative;

      & > div {
        padding-top: space(xl);
        background: color(surface);
        position: relative;

        &:after {
          width: 1px;
          background: color(neutral, 30);
          top: 100%;
          bottom: 0;
          height: 100vh;
          position: absolute;
          z-index: 0;
          content: '';
          margin-left: space(s);
          transform-origin: top center;

          @include mq(m) {
            margin-left: 0;
          }

          @each $breakpoint in map-keys($gridConfigs) {
            $breakpointMap: map-get($gridConfigs, $breakpoint);
            $columnGap: map-get($breakpointMap, 'outerGap');

            @if $columnGap == null {
              $columnGap: map-get($breakpointMap, 'columnGap');
            }

            @if $breakpoint == 'default' {
              left: $columnGap;
            } @else {
              @include mq($breakpoint) {
                left: $columnGap;
              }
            }
          }
        }

        [data-alignment='center'] &:after {
          @include mq(m) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    &.is-visible {
      display: block;
    }
  }

  [data-alignment='center'] &__trigger {
    @include mq(m) {
      left: calc(50% - 68px);
    }
  }

  &[data-navigation-open='true'] &__trigger-wrapper {
    @include mq(m) {
      transform: translateX(-7px);
    }
  }

  &.is-visible &__trigger {
    transform: translateY(0%);

    @include mq(m) {
      transform: scale(1);
    }

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }
}
