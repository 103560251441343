.quote {
  margin: 0;
  background: color(surface);

  &__grid {
    @include grid-container;
    @include get-grid-outer-gaps;
  }

  blockquote {
    color: color(primary);
    background: color(surface);
    margin: 0;
    position: relative;
    z-index: 1;
    @include get-grid((), false);

    grid-row-gap: space(s);

    @include mq(m) {
      grid-row-gap: space(m);
    }
  }

  cite {
    font-style: normal;
    display: block;
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 5 / span 7;
    }
  }

  p {
    font-style: italic;
    grid-column: 1 / -1;
    margin-top: space(s);

    @include mq(m) {
      margin-top: 0;
      grid-column: 5 / span 7;
    }
  }

  svg {
    width: 60px;
    height: 45px;
  }

  svg:first-of-type {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 4 / span 1;
    }
  }

  svg:last-of-type {
    display: none;
    grid-column: 12 / span 1;
    transform: rotate(180deg);
    align-self: flex-end;

    @include mq(m) {
      display: block;
    }
  }

  [data-alignment='center'] & {
    blockquote {
      @include mq(m) {
        text-align: center;
      }

      p,
      cite {
        @include mq(m) {
          grid-column: 3 / span 8;
        }
      }
    }

    svg:first-of-type {
      @include mq(m) {
        grid-column: 2 / span 1;
      }
    }

    svg:last-of-type {
      @include mq(m) {
        grid-column: 11 / span 1;
      }
    }
  }
}
