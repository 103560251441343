.button {
  position: relative;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  min-width: space(m);
  border-radius: space(l);

  @include mq(m) {
    min-width: space(l);
  }

  &__label {
    border-radius: space(l);
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 space(m);
    transition: color 200ms, background-color 200ms, border-color 200ms;
    width: 100%;
    overflow: hidden;
    height: space(m);
    white-space: nowrap;
    color: currentColor;
    border: 1.5px solid transparent;

    @include mq(m) {
      padding: 0 space(l);
      height: space(l);
    }

    .button--has-icon & {
      padding-right: calc(#{space(m)} + (#{space(m)} / 2));

      @include mq(m) {
        padding-right: calc(#{space(l)} + (#{space(l)} / 2));
      }
    }
  }

  &__icon {
    align-items: center;
    background: color(secondary);
    border-radius: space(m);
    color: color(on-secondary);
    display: flex;
    height: space(m);
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0px;
    width: space(m);
    transition: all 0.2s ease-in-out;

    @include mq(m) {
      width: space(l);
      height: space(l);
      border-radius: space(l);
    }
  }

  &--primary &__label {
    border-color: color(primary);
    background: color(primary);
    color: color(on-primary);
  }

  &--secondary &__label {
    background: color(secondary);
    border-color: color(secondary);
    color: color(on-secondary);
  }

  &--secondary &__icon {
    background: color(primary);
    color: color(on-primary);
  }

  &--tertiary &__label {
    background: color(neutral, 10);
    border-color: color(primary);
    color: color(primary);
  }

  &:hover &__label,
  &:focus &__label {
    color: color(on-primary);
    background: color(primary, 70);
    border-color: color(primary, 70);
  }

  &:hover &__icon,
  &:focus &__icon {
    transform: translateX(5px);
  }

  &:active &__label {
    color: color(on-primary);
    background: color(primary, 90);
    border-color: color(primary, 90);
  }

  &--secondary:hover &__label,
  &--secondary:focus &__label {
    color: color(on-secondary);
    background: color(secondary, 70);
    border-color: color(secondary, 70);
  }

  &--secondary:active &__label {
    color: color(on-secondary);
    background: color(secondary, 90);
    border-color: color(secondary, 90);
  }

  &--tertiary:hover &__label,
  &--tertiary:focus &__label {
    color: color(primary, 70);
    background: color(neutral, 10);
    border-color: color(primary, 70);
  }

  &--tertiary:active &__label {
    color: color(primary, 90);
    background: color(neutral, 10);
    border-color: color(primary, 90);
  }

  &[disabled] {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
  }

  &--hidden-label {
    border-radius: 999px;
    padding: space(xs);
  }

  &[data-animated] {
    pointer-events: none;

    .button__label {
      transition: width 0.6s ease-in-out, padding 0.6s ease-in-out;
    }

    .button__label span,
    .button__icon {
      transition: opacity 0.3s 0.7s ease-in-out, transform 0.2s ease-in-out;
    }
  }

  // stylelint-disable
  &--animate-hover,
  &[data-animated='false'] {
    transition: width 0.5s 0.1s ease-in-out;
    width: space(m);

    @include mq(m) {
      width: space(l);
    }

    .button__label {
      transition: width 0.5s 0.1s ease-in-out, padding 0.5s 0.1s ease-in-out;
      padding: 0 !important;
      overflow: hidden;

      span {
        transform: translateX(-5px);
      }
    }

    .button__label span,
    .button__icon {
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
  }

  .has-hoverable-children:hover &--animate-hover,
  .has-hoverable-children:focus &--animate-hover,
  &--animate-hover:hover,
  &--animate-hover:focus,
  &[data-animated='true'] {
    width: 100%;
    transition: width 0.5s ease-in-out;

    .button__label {
      padding: 0 space(l) !important;
      overflow: visible;
      pointer-events: auto;
      transition: all 0.5s ease-in-out;
    }

    &.button--has-icon .button__label {
      padding-right: calc(#{space(l)} + (#{space(m)} / 2)) !important;

      @include mq(m) {
        padding-right: calc(#{space(xl)} + (#{space(l)} / 2)) !important;
      }
    }

    .button__icon {
      opacity: 1;
      transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
    }

    .button__label span {
      opacity: 1;
      transform: none;
      transition: all 0.5s 0.2s ease-in-out;
    }
  }
}
