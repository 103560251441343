.file-upload {
  &__label.text {
    color: color(neutral);
    margin-bottom: space(s);
  }

  input {
    @include hide-visually();
  }

  &__custom-input {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid color(neutral, 30);
    border-radius: $borderRadius;
    background-color: color(neutral, 10);
    color: color(neutral);
    cursor: pointer;
    width: 100%;
    transition: color 200ms, background-color 200ms, border-color 200ms;
    padding: space(xs) space(s);
    height: space(l);

    &-placeholder {
      color: color(neutral, 50);
    }

    &-files {
      @include typography(form-value);
    }

    &-icon.icon {
      fill: color(neutral);
      position: absolute;
      right: space(s);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  input:disabled ~ &__custom-input {
    border-color: color(neutral, 50);
  }

  input:disabled ~ &__custom-input &__custom-input-placeholder {
    color: color(neutral, 50);
  }

  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xxs);
  }

  &.form-error &__error {
    display: block;
  }

  &.form-error &__custom-input {
    border-color: color(warning);
  }

  &--success input {
    border-color: color(success);
  }
}
