@mixin spacings-to-variables($map: $spacings) {
  @each $key in map-keys($map) {
    $space: map-get($map, $key);

    @if $space {
      --spacings-#{$key}: #{$space};
    } @else {
      @error "ERROR: #{$key} does not exist in #{$map}";
    }
  }
}

@function derive-rgb-values-from-hex($hex) {
  @return red($hex), green($hex), blue($hex);
}

@mixin colors-to-variables($map: $colors) {
  @each $key in map-keys($map) {
    $colorMap: map-get($map, $key);
    $i: "" + #{$key};

    @if type-of($colorMap) != 'map' {
      --colors-#{$i}: #{$colorMap};
    } @else {
      @each $step in map-keys($colorMap) {
        @if $step == 'default' {
          $defaultColor: map-get($colorMap, 'default');
          $colorHex: map-get($colorMap, $defaultColor);

          // return default color
          --colors-#{$i}: #{$colorHex};

          // OPTIONAL: get colors with alpha chanhel certain colors (e.g. "neutral" and "primary")
          // @if $i == 'neutral' or $i == 'primary' {
          //   $aphaValues: (10, 20, 30, 40, 50, 60, 70, 80, 90);
          //   $colorRGB: derive-rgb-values-from-hex($colorHex);

          //   @each $alphaValue in $aphaValues {
          //     --colors-#{$i}-alpha#{$alphaValue}: rgba(
          //       #{$colorRGB},
          //       #{$alphaValue / 100}
          //     );
          //   }
          // }
        } @else {
          // return step color
          --colors-#{$i}-#{$step}: #{map-get($colorMap, $step)};
        }
      }
    }
  }
}
