.video {
  position: relative;
  z-index: get-z-index('video');

  &__wrapper {
    grid-column: 1 / -1;
    padding: space(m) 0;
    background: color(neutral, 10);

    & > * + * {
      margin-top: space(s);
    }

    @include mq(m) {
      grid-column: 5 / -1;
    }

    img {
      border-radius: $borderRadius;
    }
  }

  &__iframe,
  &__player {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__embed_container {
    z-index: get-z-index('embed-container', 'video');
    border-radius: $borderRadius;
    overflow: hidden;
  }

  &__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    left: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    z-index: get-z-index('button', 'video');
    outline: 0;
    border-radius: $borderRadius;

    span {
      width: 44px;
      height: 44px;
      margin: 0 auto;
      background: color(secondary);
      color: color(on-secondary);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: space(xxl);
      transition: all 0.2s ease-in-out;

      @include mq(m) {
        width: 68px;
        height: 68px;
      }
    }
  }

  &--is-playing &__button,
  &--is-playing &__poster {
    display: none;
  }

  &__button:hover span,
  &__button:focus span {
    background: color(secondary, 70);
  }

  &__button:active span {
    background: color(secondary, 90);
  }

  [data-alignment='center'] &__wrapper {
    @include mq(m) {
      grid-column: 3 / -3;
    }
  }

  [data-alignment='center'] & h2 {
    @include mq(m) {
      text-align: center;
    }
  }
}
