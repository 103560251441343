.textarea {
  label {
    color: color('neutral');
    display: block;
    font-weight: 600;
    margin-bottom: space(xs);
  }

  &--hidden-label label {
    @include hide-visually();
  }

  &--success {
    label {
      color: color(success);
    }

    textarea {
      border-color: color(success);
    }
  }

  textarea {
    background-color: color(neutral, 10);
    border: 1px solid color(neutral, 30);
    border-radius: $borderRadius;
    color: color('neutral');
    outline: none;
    padding: space('s');
    position: relative;
    resize: none;
    width: 100%;

    &::placeholder {
      opacity: 1;
      color: color('neutral', 30);
    }

    &:focus {
      border-color: color('neutral', 30);
    }

    &:disabled {
      border-color: color(neutral, 30);
    }
  }

  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space('xs');
  }

  &.form-error &__error {
    display: block;
  }

  &.form-error {
    label {
      color: color(warning);
    }

    textarea {
      border-color: color(warning);
    }
  }
}
