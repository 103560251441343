.checkbox {
  display: block;
  width: 100%;

  &__wrapper {
    display: flex;
    position: relative;
  }

  label {
    align-items: center;
    color: color(neutral);
    cursor: pointer;
    display: flex;
    hyphens: auto;
    padding-left: calc(30px + #{space(s)});
    padding-top: space(xxs);
    position: relative;
  }

  &__custom-checkbox {
    position: absolute;
    align-items: center;
    border: 1px solid color(neutral, 30);
    border-radius: $borderRadius;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;

    svg {
      display: none;
      fill: currentColor;
      width: 100%;
      height: auto;
    }
  }

  &__error {
    color: color(warning);
    display: none;
    margin-top: space('s');
  }

  &--success .checkbox__custom-checkbox {
    border-color: color(success);
    color: color(success);
  }

  &.form-error ~ &__error {
    display: block;
  }

  &.form-error &__custom-checkbox {
    border-color: color(warning);
    color: color(warning);
  }

  input {
    @include hide-visually();

    &:disabled ~ label,
    &:disabled ~ .checkbox__custom-checkbox {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:checked ~ .checkbox__custom-checkbox svg {
      display: block;
    }

    &:focus ~ .checkbox__custom-checkbox {
      outline: 2px solid color(primary);
      outline-offset: 2px;
    }
  }

  &__required {
    color: color(neutral);
    height: 6px;
    line-height: 1;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 6px;
  }

  input:disabled ~ label {
    color: color(neutral);
  }
}
