@mixin indicator($color: primary) {
  width: space(m);
  height: space(m);
  background: color($color);
  box-shadow: 19px 19px 29px rgba(107, 107, 107, 0.12);
  border-radius: 50px;

  @include mq(m) {
    width: space(l);
    height: space(l);
  }
}

@mixin hide-visually {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin box-shadow {
  box-shadow: 3px 19px 29px 0 rgba(59, 59, 103, 0.05);

  @include mq(m) {
    box-shadow: 3px 19px 29px 0 rgba(59, 59, 103, 0.05);
  }
}
