.input {
  label {
    display: block;
    color: color(neutral);
    margin-bottom: space(xs);
  }

  &--success {
    label {
      color: color(success);
    }

    input {
      border-color: color(success);
    }
  }

  input {
    border: 1px solid color(neutral, 30);
    background-color: color(neutral, 10);
    border-radius: $borderRadius;
    color: color(neutral);
    width: 100%;
    padding: space(xs) space(s);
    height: space(l);

    &::placeholder {
      color: color(neutral, 30);
      opacity: 1;
    }

    &:disabled {
      border-color: color(neutral, 30);
    }
  }

  &__wrapper {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: space(xs);
    color: color(neutral, 70);
  }

  &--hidden-label label {
    @include hide-visually();
  }

  // stylelint-disable
  &--has-icon input {
    padding-left: 50px;

    @include mq(m) {
      padding-left: space(l);
    }
  }

  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xs);
  }

  &.form-error &__error {
    display: block;
  }

  &.form-error {
    label {
      color: color(warning);
    }

    input {
      border-color: color(warning);
    }
  }
}
