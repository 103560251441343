.breadcrumb {
  ol {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
    }

    a {
      color: color(neutral, 30);
      transition: color 0.2s ease-in-out;
      margin-right: space(xs);
      max-width: 70px;

      @include mq(m) {
        max-width: 200px;
      }

      span {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: currentColor;
      }

      &:hover,
      &:focus {
        color: color(tertiary);
      }

      &[aria-current='true'] {
        color: color(tertiary);

        span {
          border-bottom: 0;
        }
      }
    }

    li:first-of-type a {
      margin-right: 2px;
    }

    li:last-of-type a {
      max-width: 150px;

      @include mq(m) {
        max-width: 240px;
      }
    }
  }

  &__divider {
    width: 1px;
    height: 11px;
    margin-top: 2px;
    display: block;
    background: color(neutral, 30);
    margin-right: space(xs);
    border-radius: 2px;
  }

  [data-alignment='center'] & {
    @include mq(m) {
      margin-left: 0;
    }
  }
}
