.content-image {
  background: color(neutral, 10);

  figure {
    grid-column: 1 / -1;
    padding: space(m) 0;
    background: color(neutral, 10);

    @include mq(m) {
      grid-column: 5 / -1;
    }

    & > div {
      background: color(surface);
      border-radius: $borderRadius;
      overflow: hidden;
    }
  }

  figcaption {
    margin-top: space(xs);

    span {
      display: block;
      color: color(neutral, 30);
    }
  }

  &--small figure {
    @include mq(m) {
      grid-column: 5 / span 4;
    }
  }

  [data-alignment='center'] & {
    figure {
      @include mq(m) {
        grid-column: 3 / -3;
      }
    }

    &--small figure {
      @include mq(m) {
        grid-column: 5 / span 4;
      }
    }
  }
}
