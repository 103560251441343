.inline-teaser {
  position: relative;
  @include reset-page-line-gaps;

  @include mq(m) {
    margin-top: calc(#{space(xxl)} * -1);
    height: 0;
    padding-bottom: 0;
  }

  &__grid {
    @include grid-container;
    @include get-grid((), false);

    margin-bottom: space(l);

    @include mq(m) {
      background: color(neutral, 10);
      height: 0;
    }
  }

  &__link {
    grid-column: span 6;
    position: relative;
    z-index: 10;
    max-width: 300px;

    @include mq(s) {
      grid-column: span 5;
    }

    @include mq(m) {
      max-width: 390px;
      margin-left: calc((#{space(l)} / 2) * -1);
    }

    @include mq(l) {
      grid-column: span 4;
    }
  }

  &__trigger {
    width: 100%;
    outline: none;
    appearance: none;
    border: none;
    background: none;
    display: flex;

    &-label {
      padding: 2px space(s) 0;
      color: color(primary);
      flex-grow: 2;
      transition: all 0.4s 0.4s ease-in-out;

      @include mq(m) {
        padding: space(xs) space(s) 0;
      }
    }
  }

  &__card {
    position: absolute;
    opacity: 1;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &__media {
    max-width: 250px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: $borderRadius;
  }

  &__button {
    position: relative;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.4s 0.4s ease-in-out;
  }

  &__content {
    text-align: center;
    margin-top: calc((#{space(l)} / 2) * -1);
    padding: space(l) space(m) space(m);
    background: color(surface);
    box-shadow: 19px 19px 29px rgba(107, 107, 107, 0.12);
    border-radius: $borderRadius;
    opacity: 0;
    transform: translateX(-5px);
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;

    @include mq(m) {
      padding: space(l) space(l) space(m);
    }

    & > * + * {
      margin-top: space(s);
    }
  }

  &__author {
    display: flex;
    text-align: left;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      margin-right: space(s);
      filter: grayscale(100%);
    }
  }

  &__link:hover &__card,
  &__link:focus &__card {
    pointer-events: auto;
  }

  &__link:hover &__button,
  &__link:focus &__button {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  &__link:hover &__content,
  &__link:focus &__content {
    transition: all 0.4s 0.2s ease-in-out;
    transform: none;
    opacity: 1;
  }

  &__link:hover &__trigger-label,
  &__link:focus &__trigger-label {
    transform: translateX(-40px);
    transition: all 0.4s ease-in-out;
    opacity: 0;
  }

  [data-alignment='center'] & {
    @include mq(m) {
      margin-top: 0;
      height: auto;
      padding-top: space(l);
      padding-bottom: space(xxl);
    }

    .inline-teaser__grid {
      @include mq(m) {
        height: auto;
        background: none;
      }
    }

    .inline-teaser__link {
      @include mq(m) {
        grid-column: 7 / span 6;
        max-width: 450px;
        margin-left: calc((#{space(l)} / 2 + #{space(m)} / 2) * -1);
      }
    }
  }
}
