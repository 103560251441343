.content-text {
  background: color(neutral, 10);

  .richtext {
    @include grid-container;
    @include get-grid((), false);

    background: color(neutral, 10);

    & > p,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6,
    & > ul,
    & > blockquote,
    & > ol {
      grid-column: 1 / -1;
    }

    & > p,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
      @include mq(m) {
        grid-column: 5 / -1;
      }
    }

    & > blockquote,
    & > ul,
    & > ol,
    & > p.author {
      @include mq(m) {
        grid-column: 5 / -2;
      }
    }

    & > p.small {
      @include mq(m) {
        grid-column: 5 / -2;
      }
    }

    & > ul + ol,
    & > ul + ul,
    & > ol + ol,
    & > ol + ul {
      margin-bottom: 0;
    }
  }

  [data-alignment='center'] & .richtext {
    & > * {
      @include mq(m) {
        grid-column: 3 / -3;
      }
    }

    & > blockquote,
    & > ul,
    & > ol,
    & > p.author {
      @include mq(m) {
        grid-column: 4 / -4;
      }
    }

    & > p.small {
      @include mq(m) {
        grid-column: 3 / -4;
      }
    }
  }
}
