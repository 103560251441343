.home {
  width: 100%;
  background: color(neutral, 10);

  &__content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }

  &__wrapper {
    transition: transform 0.6s ease-in-out;
  }

  body[data-navigation-open='true'] &__wrapper {
    transform: translateY(50vh);
  }
}
