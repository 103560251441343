$font-family-default: 'Sofia Pro', sans-serif;
$font-family-secondary: 'GT Ultra Standard', sans-serif;

$typography: (
  headline-1: (
    fontSizes: 40 84,
    lineHeights: 1.2 1.07,
    letterSpacings: -1 -1,
    weight: 400,
    family: $font-family-secondary
  ),
  headline-2: (
    fontSizes: 32 60,
    lineHeights: 1.14 1.13,
    letterSpacings: -0.7 -0.7,
    weight: 400,
    family: $font-family-secondary
  ),
  headline-3: (
    fontSizes: 24 48,
    lineHeights: 1.16 1.125,
    letterSpacings: -0.3 -0.3,
    weight: 400,
    family: $font-family-secondary
  ),
  headline-4: (
    fontSizes: 20 36,
    lineHeights: 1.2 1.33,
    letterSpacings: -0.3 -0.3,
    weight: 400,
    family: $font-family-secondary
  ),
  headline-5: (
    fontSizes: 18 30,
    lineHeights: 1.33 1.33,
    letterSpacings: -0.2 -0.2,
    weight: 400,
    family: $font-family-secondary
  ),
  headline-6: (
    fontSizes: 18 24,
    lineHeights: 1.33 1.33,
    letterSpacings: 0 0,
    weight: 600,
    family: $font-family-secondary
  ),
  copy: (
    fontSizes: 16 19,
    lineHeights: 1.5 1.68,
    letterSpacings: 0 0,
    weight: 300
  ),
  copy-small: (
    fontSizes: 14 16,
    lineHeights: 1.42 1.5,
    letterSpacings: 0 0,
    weight: 400
  ),
  topline: (
    fontSizes: 12 16,
    lineHeights: 1.33 2,
    letterSpacings: 2 2,
    weight: 600,
    transform: uppercase,
    family: $font-family-secondary
  ),
  copy-info: (
    fontSizes: 12 14,
    lineHeights: 1.33 1.7,
    letterSpacings: 1.75 1.75,
    transform: uppercase,
    style: italic,
    weight: 300
  ),
  quote: (
    fontSizes: 28 48,
    lineHeights: 1.28 1.4,
    letterSpacings: 0 0,
    weight: 300,
    style: italic,
    family: $font-family-secondary
  ),
  quote-small: (
    fontSizes: 18 24,
    lineHeights: 1.55 1.33,
    letterSpacings: 0 0,
    weight: 300,
    style: italic,
    family: $font-family-secondary
  ),
  link: (
    fontSizes: 14 16,
    lineHeights: 1.5 1.5,
    letterSpacings: 0 0
  ),
  button: (
    fontSizes: 18 24,
    lineHeights: 2 1.5,
    letterSpacings: 0 0,
    family: $font-family-secondary
  ),
  form-label: (
    fontSizes: 14 16,
    lineHeights: 1.4 1.25,
    letterSpacings: 0 0,
    weight: 600
  ),
  form-value: (
    fontSizes: 14 16,
    lineHeights: 1.4 1.25,
    letterSpacings: 0 0
  ),
  form-hint: (
    fontSizes: 14 14,
    lineHeights: 1.4 1.4,
    letterSpacings: 0 0,
    weight: 300
  )
);
