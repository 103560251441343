.indicator {
  width: space(m);
  height: space(m);

  @include mq(m) {
    width: space(l);
    height: space(l);
  }

  & > div {
    width: space(m);
    height: space(m);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 0.4s 0.1s ease-in-out;
    position: relative;

    @include mq(m) {
      width: space(l);
      height: space(l);
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 50px;
      box-shadow: 0px 0px 0px rgba(59, 59, 103, 0.15);
      transition: all 0.4s 0.4s ease-in-out;

      @include mq(m) {
        box-shadow: 0px 0px 0px rgba(59, 59, 103, 0.15);
      }
    }
  }

  &--primary > div {
    background: color(primary);
    color: color(on-primary);
  }

  &--secondary > div {
    background: color(secondary);
    color: color(on-secondary);
  }

  &--tertiary > div {
    background: color(tertiary);
    color: color(on-tertiary);
  }

  &--static > div {
    color: color(neutral);
  }

  &--large,
  &--large > div {
    width: space(l);
    height: space(l);
  }

  &--scaled > div {
    transform: scale(0);
  }

  &[data-animated='true'] > div:after {
    box-shadow: 3px 19px 29px 0 rgba(59, 59, 103, 0.15);

    @include mq(m) {
      box-shadow: 3px 19px 29px 0 rgba(59, 59, 103, 0.15);
    }
  }

  &--scaled[data-animated='true'] > div {
    transform: scale(1);
  }

  &[type='button']:focus > div,
  &[type='button']:hover > div {
    color: color(on-primary);
    background: color(primary, 70);
    border-color: color(primary, 70);
  }

  &[type='button']:active > div {
    color: color(on-primary);
    background: color(primary, 90);
    border-color: color(primary, 90);
  }

  &--secondary[type='button']:focus > div,
  &--secondary[type='button']:hover > div {
    color: color(on-secondary);
    background: color(secondary, 70);
    border-color: color(secondary, 70);
  }

  &--secondary[type='button']:active > div {
    color: color(on-secondary);
    background: color(secondary, 90);
    border-color: color(secondary, 90);
  }

  &--tertiary[type='button']:focus > div,
  &--tertiary[type='button']:hover > div {
    color: color(on-tertiary);
    background: color(tertiary, 70);
    border-color: color(tertiary, 70);
  }

  &--tertiary[type='button']:active > div {
    color: color(on-tertiary);
    background: color(tertiary, 90);
    border-color: color(tertiary, 90);
  }
}
