.listicle {
  &__grid {
    @include grid-container;
  }

  &__header {
    position: relative;
    background: color(neutral, 10);
    @include get-grid((), false);

    grid-row-gap: space(s);

    @include mq(m) {
      grid-row-gap: 0;
    }
  }

  &__indicator {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(0, -10px);

    @include mq(m) {
      transform: translate(-50%, -10px);
    }
  }

  h2 {
    grid-column: 2 / -1;
    grid-row: 1;

    @include mq(m) {
      grid-column: 2 / span 6;
    }
  }

  &__image {
    grid-column: 2 / -1;
    overflow: hidden;
    border-radius: $borderRadius;

    @include mq(m) {
      grid-row: 1;
      grid-column: 8 / span 5;
    }
  }

  &__content {
    @include get-grid((), false);

    background: color(neutral, 10);
    padding-top: space(m);
    padding-bottom: space(m);

    & > div {
      grid-column: 1 / -1;

      @include mq(m) {
        grid-column: 5 / span 8;
      }
    }
  }

  &__item {
    position: relative;

    & + & {
      padding-top: space(l);

      @include mq(m) {
        padding-top: space(xl);
      }
    }
  }

  [data-alignment='center'] & {
    .listicle__content > div,
    h2 {
      @include mq(m) {
        grid-column: 3 / -3;
      }
    }

    h2 {
      @include mq(m) {
        text-align: center;
        grid-row: 3;
        margin-top: space(l);
      }
    }

    .listicle__indicator {
      @include mq(m) {
        grid-column: 1 / -1;
        position: relative;
        margin: 0 auto;
        grid-row: 1;
        z-index: 1;
        transform: none;
      }
    }

    .listicle__image {
      @include mq(m) {
        grid-column: 4 / -4;
        grid-row: 2;
        margin-top: calc((#{space(l)} / 2) * -1);
      }

      & + h2 {
        @include mq(m) {
          margin-top: space(m);
        }
      }
    }
  }
}
