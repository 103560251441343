.header {
  background: color(surface);
  position: relative;

  &__wrapper {
    padding-top: 0;

    @include mq(m) {
      padding-top: space(l);
      padding-bottom: space(m);
    }
  }

  &__content {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-direction: column;
    padding: space(s) 0 0 0 !important;
  }

  &__logo {
    position: relative;
    z-index: 2;
    align-self: flex-end;

    svg {
      width: 90px;

      @include mq(m) {
        width: 150px;
      }
    }
  }

  &__breadcrumb {
    padding: space(xs) 0;
    margin-left: 6px;
    background: color(surface);
    margin-top: space(m);

    @include mq(m) {
      margin-top: space(xs);
      margin-left: calc((#{space(m)} / 2) * -1);
    }

    [data-alignment='center'] & {
      @include mq(m) {
        align-self: center;
      }
    }
  }
}
