.logo-wall {
  &__wrapper {
    background: color(neutral, 10);
    @include grid-container;
    @include get-grid((), false);
  }

  &__content {
    padding-bottom: space(s);
    grid-column: 1 / -1;

    @include mq(m) {
      padding-bottom: space(m);
      grid-column: 2 / -2;
    }

    & > * + * {
      margin-top: space(xs);

      @include mq(m) {
        margin-top: space(m);
      }
    }
  }

  &__logos {
    display: flex;
    margin: 0 -7px;
    align-items: stretch;
    flex-wrap: wrap;
    grid-column: 1 / -1;

    @include mq(m) {
      margin: 0 calc(#{space(s)} * -1);
    }

    @include mq(l) {
      grid-column: 2 / -2;
    }
  }

  &__logo {
    width: calc(50% - 14px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 7px 15px;
    border: 1px solid color(neutral, 30);
    border-radius: $borderRadius;
    padding: space(xs);

    @include mq(m) {
      padding: space(s);
      margin: space(s) space(s);
      width: calc(33.33% - #{space(s)} * 2);
    }

    @include mq(l) {
      padding: space(m);
    }

    & > div {
      width: 100%;

      img {
        object-fit: contain;
      }
    }

    p {
      text-align: center;
      margin-top: space(s);
    }

    svg {
      margin-top: space(xs);
      transition: transform 0.2s ease-in-out;
    }
  }

  a.logo-wall__logo {
    transition: all 0.2s ease-in-out;

    p {
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      border-color: color(tertiary);
      color: color(tertiary);

      svg {
        transform: translate(5px, -5px);
      }
    }
  }

  [data-alignment='center'] &__logos {
    @include mq(m) {
      grid-column: 1 / -1;
    }

    &.is-centered {
      @include mq(m) {
        justify-content: center;
      }
    }
  }

  [data-alignment='center'] &__logo {
    @include mq(m) {
      width: calc(33.33% - #{space(s)} * 2);
    }

    @include mq(l) {
      width: calc(25% - #{space(s)} * 2);
    }
  }

  [data-alignment='center'] &__content {
    @include mq(m) {
      text-align: center;
    }
  }
}
