.link-list {
  &__grid {
    @include grid-container;
    @include get-grid(());

    background: color(neutral, 10);
    grid-row-gap: space(l);
    padding-top: space(l);
    padding-bottom: space(l);
    position: relative;
  }

  h2,
  &__items {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 5 / -1;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;

    @include mq(m) {
      grid-column: 5 / -1;
    }

    @include mq(l) {
      grid-column: 5 / -2;
    }

    & > * + * {
      margin-top: space(m);
    }

    a {
      display: flex;
      align-items: center;

      p {
        flex-grow: 2;
        padding-right: space(m);
      }

      .button {
        flex-shrink: 0;
        min-height: space(m);

        @include mq(m) {
          min-height: space(l);
        }
      }
    }
  }

  [data-alignment='center'] & h2 {
    @include mq(m) {
      grid-column: 3 / -3;
    }
  }

  [data-alignment='center'] &__items {
    @include mq(m) {
      grid-column: 3 / -3;
    }

    @include mq(l) {
      grid-column: 3 / -4;
    }
  }
}
