.tag {
  padding: 0 space(xs);
  background: transparent;
  color: color(primary);
  border: 1.5px solid color(primary);
  border-radius: space(m);
  min-height: 26px;
  transition: background 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(m) {
    min-height: 36px;
    padding: 0 space(s);
  }

  &:hover {
    border-color: color(primary, 70);
    color: color(primary, 70);
  }

  &:focus,
  &:active {
    border-color: color(primary, 90);
    color: color(primary, 90);
  }

  &--is-active {
    background: color(secondary);
    border-color: color(secondary);
    color: color(on-secondary);

    &:focus,
    &:hover {
      background: color(secondary, 70);
      border-color: color(secondary, 70);
      color: color(on-secondary);
    }

    &:active {
      background: color(secondary, 90);
      border-color: color(secondary, 90);
      color: color(on-secondary);
    }
  }
}
