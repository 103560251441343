html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'] {
  box-sizing: border-box;
}

html {
  // Optimize text rendering speed

  text-rendering: optimizeSpeed;
  // Prevents iOS text size adjust after orientation change, without disabling

  text-size-adjust: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

:root {
  @each $name, $value in $typography {
    // Loop over typography keys and build css variables
    @include adaptive-size-css-variables(#{$name}, $value);
  }

  @include spacings-to-variables();
}

body {
  color: color(neutral);
  background: color(surface);
  font-family: $font-family-default;
  // enable font smoothing http://szafranek.net/works/articles/font-smoothing-explained/

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
select,
button {
  font-family: $font-family-default;
}

input,
textarea,
select {
  @include typography('form-value');
}

// Reset default UA Styles for typographical elements
figure {
  margin: 0;
}

a {
  color: inherit;
  position: relative;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

img[data-src] {
  opacity: 0;
  transition: opacity 400ms;
}

img[src] {
  opacity: 1;
  transition: opacity 400ms;
}

input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button,
select {
  color: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}

[data-whatinput='keyboard'] *:focus {
  outline: 2px solid color(primary);
  outline-offset: 2px;
}

::selection {
  background: color(primary);
  color: color(on-primary);
}

.has-grid {
  @include grid-container;
  @include get-grid();
}
