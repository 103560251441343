.author-info {
  &__grid {
    @include grid-container;
    @include get-grid-outer-gaps;
  }

  &__container {
    @include get-grid((), false);

    padding-top: space(s);
    padding-bottom: space(s);
    background: color(neutral, 10);
    position: relative;
    z-index: 1;

    @include mq(m) {
      padding-top: space(m);
      padding-bottom: space(m);
    }
  }

  &__person {
    display: flex;
    color: color(neutral, 70);
    align-items: center;
  }

  &__image {
    background-color: color(neutral, 30);
    border-radius: 200px;
    overflow: hidden;
    margin-right: space(s);
    width: 120px;
    height: 120px;
    flex-shrink: 0;

    @include mq(m) {
      width: 200px;
      height: 200px;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    margin-top: space(xs);

    & > * + * {
      margin-top: space(xs);
    }
  }

  a {
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      color: color(primary);
    }
  }

  &--has-grid &__person {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 5 / -1;
    }
  }

  [data-alignment='center'] &__grid {
    padding-top: space(xl);
  }

  [data-alignment='center'] &--has-grid &__person {
    @include mq(m) {
      grid-column: 7 / -1;
      transform: translateX(calc(-100px - #{space(s)}));
    }
  }
}
