.stage {
  background: color(surface);

  &__wrapper {
    @include get-grid-outer-gaps;
    @include grid-container;
    @include reset-page-line-gaps;
  }

  &__grid,
  &__intro-grid {
    @include get-grid((), false);

    position: relative;
    background: color(surface);
  }

  &__intro-grid {
    @include mq(m) {
      background: transparent;
    }
  }

  &__indicator {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(0, -10px);
    background: color(surface);

    @include mq(m) {
      transform: translate(-50%, -15px);
    }
  }

  &__content {
    grid-column: 1 / -1;
    color: color(neutral);
    margin-bottom: space(s);

    @include mq(m) {
      grid-column: 2 / -2;
      margin-bottom: 0;
    }

    & > * + * {
      margin-top: space(m);

      @include mq(m) {
        margin-top: space(l);
      }
    }

    .has-media & {
      @include mq(m) {
        grid-column: 2 / span 6;
      }
    }
  }

  &__logo {
    height: 20px;

    @include mq(m) {
      height: 30px;
    }
  }

  &__topline {
    color: color(tertiary);
    padding-left: calc(#{space(m)} + #{space(xs)});

    @include mq(m) {
      padding-left: 0;
    }
  }

  h1 {
    hyphens: auto;
  }

  &__headlines {
    & > * + * {
      margin-top: space(s);

      @include mq(m) {
        margin-top: space(m);
      }
    }

    .has-no-topline & {
      margin-top: space(l);

      @include mq(m) {
        margin-top: calc((#{space(l)} / 2) * -1);
      }
    }
  }

  &__media {
    grid-column: 1 / -1;
    margin-top: space(m);
    padding-bottom: space(s);
    max-width: 600px;

    @include mq(m) {
      max-width: none;
      margin-top: 0;
      padding-bottom: 0;
      grid-column: span 5;
    }

    & > div {
      border-radius: $borderRadius;
      overflow: hidden;
    }

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__intro {
    font-weight: 600;
    padding-top: space(l);

    p {
      font-weight: 600;
    }

    @include mq(m) {
      padding-top: space(xxl);
    }

    &-text {
      grid-column: 1 / -1;
      padding: space(s) 0;

      @include mq(m) {
        padding: 0;
        grid-column: 5 / -1;
      }
    }

    p {
      margin: space(s) 0 0;
    }

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  // Center aligned page line
  [data-alignment='center'] & {
    .stage__grid {
      @include mq(m) {
        background: none;
      }
    }

    .stage__headlines {
      @include mq(m) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: space(m) 0;
        background: color(surface);
      }
    }

    .stage__indicator {
      @include mq(m) {
        position: relative;
        left: auto;
        transform: none;
        top: auto;
        justify-self: center;
        grid-column: 1 / -1;
      }
    }

    .stage__content {
      @include mq(m) {
        text-align: center;
        grid-column: 1 / -1;
      }
    }

    &.has-no-topline .stage__headlines {
      @include mq(m) {
        margin-top: 0;
      }
    }

    .stage__topline {
      @include mq(m) {
        padding: space(m) 0 space(s);
        background: color(surface);
      }
    }

    .stage__intro-text {
      @include mq(m) {
        grid-column: 3 / -3;
        padding: space(m) 0;
        text-align: center;
      }
    }

    .stage__intro-grid {
      @include mq(m) {
        background: color(surface);
      }
    }

    .stage__media {
      @include mq(m) {
        padding: space(l) 0 space(m);
        grid-column: 4 / -4;
        background: color(surface);
      }
    }

    &.has-media {
      .stage__content {
        @include mq(m) {
          grid-column: 2 / -2;
        }
      }

      .stage__headlines {
        @include mq(m) {
          padding-bottom: 0;
        }
      }
    }
  }
}
