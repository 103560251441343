.glossary-popup {
  opacity: 0;
  pointer-events: none;
  z-index: 9998;
  height: auto;
  border-radius: space(xs);
  color: color(on-primary);
  overflow: hidden;
  padding: space(m);
  width: 320px;
  transition: opacity 0.2s;

  @include mq(s) {
    width: 375px;
  }

  @include mq(m) {
    padding: space(m);
  }

  .text {
    font-weight: 600;
  }

  a.link {
    display: inline-flex;
    align-items: center;
    color: color(neutral, 10);
    text-decoration: underline;
    text-underline-offset: 3px;
    margin-left: 0;
    margin-top: space(s);

    &:before {
      display: none;
    }

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.42 9.495a.75.75 0 0 1 1.06-.05L34.4 19.371a.85.85 0 0 1 0 1.258L23.48 30.555a.75.75 0 0 1-1.009-1.11l9.565-8.695H7.976a.75.75 0 0 1 0-1.5h24.06l-9.565-8.695a.75.75 0 0 1-.05-1.06z' fill='%23fff'/%3E%3C/svg%3E%0A");
      margin-left: space(xs);
      width: 30px;
      height: 30px;
      transform: translateX(0px);
      transition: transform 0.2s ease-in-out;
    }

    &:hover:after,
    &:focus:after {
      transform: translateX(5px);
    }
  }

  & > * {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0.3s;
  }

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    border-radius: space(xs);
    background-color: color(primary);
    transition: all 0.3s ease-in-out;
  }

  &[data-popper-placement='top-start']:before {
    transform-origin: bottom left;
  }

  &[data-popper-placement='top-end']:before {
    transform-origin: bottom right;
  }

  &[data-popper-placement='bottom-start']:before {
    transform-origin: top left;
  }

  &[data-popper-placement='bottom-end']:before {
    transform-origin: top right;
  }

  &.visible,
  &:hover,
  &:focus-within {
    opacity: 1;
    pointer-events: auto;

    &:before {
      transform: scale(1);
    }

    & > * {
      opacity: 1;
    }
  }
}
