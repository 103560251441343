.downloads {
  background: color(neutral, 10);

  &__wrapper {
    @include get-grid((), false);

    position: relative;
    z-index: 1;
    background: color(neutral, 10);
  }

  &__content,
  h3 {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 5 / -1;
    }
  }

  h3 {
    padding-bottom: space(m);
  }

  a {
    margin-bottom: space(s);
    display: flex;
    transition: all 0.2s ease-in-out;
    margin-left: space(xxs);

    svg {
      margin-right: space(xs);
      flex-shrink: 0;
      transition: transform 0.2s ease-in-out;

      @include mq(m) {
        margin-right: space(s);
      }
    }

    @include mq(m) {
      margin-left: 0;
      margin-bottom: space(m);
    }

    &:last-of-type {
      @include mq(m) {
        margin-bottom: 0;
      }
    }

    p {
      margin-bottom: space(xs);
    }

    &:hover,
    &:focus {
      color: color(primary);

      svg {
        transform: translateY(5px);
      }
    }
  }

  [data-alignment='center'] &__content,
  [data-alignment='center'] & h3 {
    @include mq(m) {
      grid-column: 3 / -3;
    }
  }
}
