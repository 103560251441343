.main-stage {
  position: relative;
  background: color(surface);

  &__languages {
    display: flex;
    padding-left: space(l);

    @include mq(m) {
      padding-left: 0;
      justify-content: flex-end;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
    }

    li {
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }

    a {
      color: color(neutral, 30);
      transition: color 0.2s ease-in-out;

      &:hover,
      &:focus {
        color: color(tertiary);
      }

      &[aria-current='true'] {
        color: color(neutral, 50);
        pointer-events: none;
      }
    }

    &-divider {
      width: 1px;
      height: 11px;
      display: block;
      background: color(neutral, 30);
      border-radius: 2px;
      margin: 0 space(xs);
    }
  }

  &__group {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: space(m);
    padding-bottom: space(m) !important;
    padding-top: space(m);

    &[data-theme='rational'] {
      @include colors-to-variables($rationalColors);
    }

    &[data-theme='emotional'] {
      @include colors-to-variables($emotionalColors);
    }

    @include mq(m) {
      margin-bottom: space(l);
      grid-template-columns: 1fr space(l) 1fr;
    }
  }

  & > div > * + * {
    padding-top: 0;
  }

  &__group-title {
    padding-left: space(l);
    grid-row: 1;
    grid-column: 1 / span 1;
    transition: transform 0.4s ease-in-out;
    opacity: 0;
    transform: translateY(50px);
    cursor: pointer;

    @include mq(m) {
      padding-right: space(xl);
      padding-left: space(l);
      grid-column: 1 / span 1;
    }

    h2 {
      transform-origin: center left;
      transition: transform 0.4s ease-in-out;
      margin-bottom: space(xxs);

      @include mq(m) {
        margin-bottom: 0;
      }
    }
  }

  &__group-trigger {
    grid-row: 1;
    opacity: 0;
    transform: translateY(50px);
    grid-column: 1 / span 1;
    transition: transform 0.4s ease-in-out;
    position: relative;
    border-radius: 999px;
    z-index: 1;

    @include mq(m) {
      margin-left: calc(#{space(l)} / 2 * -1);
    }
  }

  &__group-links {
    position: relative;
    height: 0px;
    opacity: 0;
    grid-row: 2;
    grid-column: 1 / span 1;

    nav {
      position: absolute;
      top: 0;
      left: 0;
      padding: space(m) 0 0;
    }

    li {
      opacity: 0;
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      margin-left: space(s);

      @include mq(m) {
        margin-left: 0;
      }

      & > li + li {
        margin-top: space(m);

        @include mq(m) {
          margin-top: space(l);
        }
      }
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    padding: space(s);
    padding-right: calc(#{space(s)} + 15px);
    padding-bottom: calc(#{space(s)} - 5px);
    transform: translateX(calc(#{space(m)} / 2 * -1));
    border-radius: 9999px;
    --delay: 0.4s;

    @include mq(m) {
      transform: translateX(calc(#{space(l)} / 2 * -1));
    }

    &-label {
      position: relative;
      display: inline-flex;
      align-items: center;
      z-index: 1;
      color: color(on-primary);
      max-width: 350px;
      opacity: 0;
      transform: translate(-5%, -5%);

      svg {
        flex-shrink: 0;
        margin-right: space(xs);
        transition: transform 0.2s ease-in-out;
      }
    }

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: space(m);
      height: space(m);
      border-radius: 9999px;
      background: color(primary);
      margin-right: space(m);
      transform-origin: calc(#{space(m)} / 2) top;
      transform: scale(0.5);
      transition: all 0s ease-in-out;

      @include mq(m) {
        width: space(l);
        height: space(l);
        transform-origin: calc(#{space(l)} / 2) top;
      }
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        transform: translateX(5px);
      }
    }

    &:hover .main-stage__link-background,
    &:focus .main-stage__link-background {
      background: color(primary, 70);
    }

    &:active .main-stage__link-background {
      background: color(primary, 70);
    }
  }

  &__group.is-active &__group-title {
    transform: translateY(-8px) !important;

    @include mq(m) {
      transform: translateY(-20px) !important;
    }

    h2 {
      transform: scale(1.1) translateY(-8px);

      @include mq(m) {
        transform: scale(1.2) translateY(-2px);
      }
    }
  }

  &__group.is-active &__group-trigger {
    transform: translateY(-20px) !important;
  }

  &__group.is-active &__link {
    &-label {
      opacity: 1;
      transform: translate(0, 0);
      transition: all 0.2s calc(var(--delay) + 0.6s) ease-in-out;

      @include mq(m) {
        transition: all 0.2s calc(var(--delay) + 1.2s) ease-in-out;
      }
    }
  }

  &__group--secondary {
    // stylelint-disable
    .main-stage__link-background {
      background: color(secondary);
    }

    .main-stage__link-label {
      color: color(on-secondary);
    }

    .main-stage__link:hover .main-stage__link-background,
    .main-stage__link:focus .main-stage__link-background {
      background: color(secondary, 70);
    }

    .main-stage__link:active .main-stage__link-background {
      background: color(secondary, 70);
    }
  }

  [data-alignment='center'] &__group-title {
    @include mq(m) {
      text-align: right;
      justify-self: end;
      padding-left: space(m);
    }

    h2 {
      @include mq(m) {
        transform-origin: center right;
      }
    }
  }

  [data-alignment='center'] &__group {
    @include mq(m) {
      padding-bottom: 0 !important;
    }
  }

  [data-alignment='center'] &__group-trigger {
    @include mq(m) {
      grid-column: 2 / span 1;
      margin-left: 0;
    }
  }

  [data-alignment='center'] &__link {
    @include mq(m) {
      transform: translateX(calc(#{space(m)} / 2 * -1));
    }
  }

  [data-alignment='center'] &__group-links {
    @include mq(m) {
      grid-row: 1;
      grid-column: 3 / span 1;
    }

    ul {
      @include mq(m) {
        margin-left: calc(#{space(m)} * -1);
      }
    }

    nav {
      @include mq(m) {
        padding: space(xxl) 0 0;
      }
    }
  }
}
