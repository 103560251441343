.teaser {
  position: relative;
  border-radius: $borderRadius;

  &__wrapper > * + * {
    margin-top: space(s);

    @include mq(m) {
      margin-top: space(m);
    }
  }

  &__wrapper {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 15px;

    @include mq(m) {
      display: block;
    }
  }

  &__media {
    grid-column: 1 / -3;
    width: 100%;
    transition: transform 0.4s ease-in-out;

    & > div {
      width: 0%;
      border-radius: $borderRadius;
      background: color(neutral, 10);
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }
  }

  &__content {
    position: relative;
    grid-column: 2 / -1;
    transition: transform 0.4s ease-in-out;
  }

  &__subline {
    margin-top: space(s);

    @include mq(m) {
      margin-top: space(m);
    }
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color(surface, 10);
    transform: scaleX(1);
    transform-origin: right center;
    margin-top: 0;
  }

  &--without-media {
    align-self: center;
  }

  &:hover &__media,
  &:focus &__media,
  &:hover &__content,
  &:focus &__content {
    transform: translateX(10px);
  }

  &[data-animated='true'] &__wrapper {
    opacity: 1;
  }
}
