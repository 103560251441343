.hero-teaser {
  overflow: hidden;
  background: color(surface);

  &__link {
    @include grid-container;
    @include get-grid(
      (
        s: 1,
        m: 2
      ),
      false,
      false
    );

    cursor: pointer;
    padding-top: space(xl);
    padding-bottom: space(xl);
  }

  &__media,
  &__content {
    grid-column: 1 / -1;
  }

  &__media {
    transform: translateX(#{space(s)});
    width: calc(100% + 10px);
    overflow: hidden;

    & > div {
      background-color: color(neutral, 10);
    }

    @include mq(m) {
      transform: none;
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
      width: calc(100% + 60px);
    }

    @include mq(l) {
      width: calc(100% + 120px);
    }
  }

  &__content {
    position: relative;
    margin-bottom: space(m);
    margin-top: space(l);
    align-self: center;
    grid-row: 1;
    grid-column: 1 / -1;
    padding-left: space(l);

    @include mq(m) {
      margin-bottom: space(l);
      padding: 0 space(xl);
      grid-row: 1;
    }

    @include mq(m) {
      grid-column: 1 / span 1;
    }

    h2 {
      hyphens: auto;
    }

    .richtext {
      margin-top: space(m);
    }
  }

  &__indicator {
    position: absolute;
    top: 0;
    left: 0;
    grid-row: 1;

    @include mq(m) {
      left: calc(#{space(l)} / 2 * -1);
    }
  }

  &__button {
    display: inline-flex;
    max-width: 500px;
    margin-top: space(m);
    transition: transform 0.2s linear;
    opacity: 0;

    @include mq(m) {
      grid-row: 2;
      grid-column: 1 / span 1;
      margin-top: 0;
      margin-left: calc(#{space(l)} / 2 * -1);
    }
  }

  [data-alignment='center'] &__media {
    @include mq(m) {
      grid-column: 1 / span 1;
      margin-left: -60px;
    }

    @include mq(l) {
      margin-left: -120px;
    }
  }

  [data-alignment='center'] &__content,
  [data-alignment='center'] &__button {
    @include mq(m) {
      grid-column: 2 / span 1;
    }
  }
}
