.select {
  label {
    color: color('neutral');
    display: block;
    line-height: 1;
    margin-bottom: space(xs);
  }

  &--hidden-label label {
    @include hide-visually();
  }

  &--success {
    label {
      color: color(success);
    }

    select {
      border-color: color(success);
    }
  }

  svg {
    pointer-events: none;
    position: absolute;
    right: space('s');
    top: 50%;
    transform: translateY(-50%);
  }

  &__wrapper {
    position: relative;
  }

  select {
    appearance: none;
    background: color('neutral', 10);
    border: 1px solid color(neutral, 30);
    border-radius: $borderRadius;
    color: color('neutral');
    line-height: 1;
    outline: none;
    width: 100%;
    padding: space(xs) space(s);
    height: space(l);

    option {
      color: color('neutral');
    }

    &:disabled {
      border-color: color(neutral, 30);
    }

    &::-ms-expand {
      display: none;
    }
  }

  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xs);
  }

  &.form-error &__error {
    display: block;
  }

  &.form-error {
    label {
      color: color(warning);
    }

    select {
      border-color: color(warning);
    }
  }
}
