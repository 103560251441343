.standard-teasers {
  background: color(surface);

  &__grid,
  &__wrapper {
    @include get-grid((), false);
  }

  &__headline {
    background: color(surface);
    grid-column: 1 / -1;
    padding: space(s) 0;
    margin-bottom: space(l);

    @include mq(m) {
      grid-column: 2 / -2;
    }
  }

  &__wrapper {
    grid-column: 1 / -1;
  }

  &__wrapper[aria-busy='true'] {
    opacity: 0.5;
  }

  &__teaser {
    grid-column: 1 / span 6;
    margin-bottom: space(l);

    @include mq(m) {
      margin-bottom: space(xl);
      width: calc(100% + #{space(m)});
    }
  }

  &__teaser:nth-of-type(odd) {
    @include mq(m) {
      grid-column: 1 / span 5;
    }

    @include mq(l) {
      grid-column: 2 / span 4;
    }
  }

  &__teaser:nth-of-type(even) {
    @include mq(m) {
      margin-left: calc(#{space(m)} * -1);
      grid-column: 8 / span 5;
    }

    @include mq(l) {
      grid-column: 8 / span 4;
    }
  }

  &__filter {
    position: relative;
    border-radius: $borderRadius;
    background: color(neutral, 10);
    width: 100%;
    padding: space(s);
    display: flex;
    flex-direction: column;
    margin-bottom: space(xxl);
    grid-column: 1 / -1;
    @include box-shadow;

    @include mq(m) {
      padding: space(m) space(l) space(xs);
      transform: translateX(calc(#{space(l)} / 2 * -1));
      width: calc(100% + (#{space(l)} / 2));
      grid-column: 1 / -2;
    }

    &-list {
      & > h4 {
        color: color(neutral, 50);
      }

      & > * + * {
        margin-top: space(xs);

        @include mq(m) {
          margin-top: space(s);
        }
      }
    }

    &-reset {
      position: absolute;
      top: space(s);
      right: space(s);

      &:hover,
      &:focus {
        color: color(primary);
      }

      @include mq(m) {
        top: space(m);
        right: space(l);
      }

      span {
        display: none;

        @include mq(m) {
          display: block;
        }
      }

      svg {
        @include mq(m) {
          display: none;
        }
      }
    }

    &-trigger {
      display: flex;
      align-items: center;
      border-radius: $borderRadius;
      margin-top: space(xs);

      &:hover {
        color: color(primary);
      }

      span {
        margin-right: space(xxs);
      }

      [disabled] {
        cursor: not-allowed;
      }

      svg {
        transition: transform 0.4s ease-in-out;
      }

      &.expanded svg {
        transform: rotate(-180deg);
      }
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      margin: space(s) calc(#{space(xs)} / 2 * -1) 0;

      @include mq(m) {
        margin: space(s) calc(#{space(s)} / 2 * -1) 0;
      }

      & > * {
        margin: 0 calc(#{space(xs)} / 2) space(xs);

        @include mq(m) {
          margin: 0 calc(#{space(s)} / 2) space(s);
        }
      }
    }
  }

  [data-alignment='center'] &__headline {
    @include mq(m) {
      text-align: center;
    }
  }

  [data-alignment='center'] &__filter {
    @include mq(m) {
      justify-self: center;
      width: 100%;
      grid-column: 2 / -2;
      max-width: none;
      transform: none;
    }

    &-list {
      @include mq(m) {
        text-align: center;
      }
    }

    &-trigger {
      @include mq(m) {
        align-self: center;
        align-items: center;
      }
    }

    &-items {
      @include mq(m) {
        align-items: center;
        justify-content: center;
      }
    }
  }
}
