.form {
  &__field {
    margin-bottom: space(m);
  }

  &__response {
    color: color(neutral, 50);
    margin-top: space(m);
    padding: space(xs);
    display: none;
    border-radius: $borderRadius;
    border: 1px solid transparent;

    &[data-state='success'] {
      display: block;
      border-color: color(success);
    }

    &[data-state='error'] {
      display: block;
      border-color: color(warning);
    }
  }
}
