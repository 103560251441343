[data-theme='neutral'] {
  @include colors-to-variables();
}

[data-theme='rational'] {
  @include colors-to-variables($rationalColors);
}

[data-theme='emotional'] {
  @include colors-to-variables($emotionalColors);
}
