.content-blocks {
  &__block {
    // Reset richtext bottom padding for specific next block
    &[data-block='richtext'][data-next-block='image'],
    &[data-block='richtext'][data-next-block='video'],
    &[data-block='richtext'][data-next-block='gallery'],
    &[data-block='richtext'][data-next-block='accordion'] {
      .content-text__grid,
      .richtext {
        padding-bottom: 0;
      }
    }

    // Reset richtext top padding for image + richtext
    &[data-block='richtext'][data-prev-block='image'],
    &[data-block='richtext'][data-prev-block='video'],
    &[data-block='richtext'][data-prev-block='gallery'],
    &[data-block='richtext'][data-prev-block='accordion'] {
      .content-text__grid,
      .richtext {
        padding-top: 0;
      }
    }

    &[data-block='richtext'][data-prev-block='inline-teaser'] {
      .content-text__grid {
        padding-top: 0;
      }
    }

    &[data-block='author-info'][data-prev-block='richtext'] {
      .author-info__grid {
        padding-top: 0;
      }
    }

    &[data-block='author-info']:not([data-next-block='richtext']) {
      .author-info__grid {
        @include get-page-line-bottom-gap;
      }
    }

    &[data-block='bubble-box'][data-prev-block='author-info'] {
      margin-top: space(m);

      .bubble-box__grid,
      .bubble-box__list {
        padding-top: 0;
      }
    }

    &[data-block='inline-teaser'][data-next-block='contact-form'] {
      .inline-teaser {
        padding-bottom: 0 !important;
      }
    }

    &[data-block='contact-form'][data-prev-block='inline-teaser'] {
      .contact-form {
        padding-top: 0 !important;
      }
    }

    // Reset inline teaser negative margin for inline teaser + inline teaser
    [data-alignment='left'] &[data-block='inline-teaser'][data-prev-block='inline-teaser'] {
      .inline-teaser {
        @include mq(m) {
          margin-top: space(l);
          margin-bottom: calc(#{space(l)} * -1);
        }
      }
    }

    [data-alignment='center'] &[data-block='inline-teaser'][data-prev-block='inline-teaser'] {
      .inline-teaser {
        @include mq(m) {
          margin-top: calc(#{space(l)} * -1);
        }
      }
    }
  }
}
