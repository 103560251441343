$borderRadius: 10px;

$spacings: (
  xxs: 4px,
  xs: 12px,
  s: 20px,
  m: 40px,
  l: 60px,
  xl: 80px,
  xxl: 100px
);
