.icon {
  &--tiny {
    height: space(s);
    width: space(s);
  }

  &--small {
    height: 30px;
    width: 30px;

    @include mq(m) {
      height: space(m);
      width: space(m);
    }
  }

  &--large {
    height: space(l);
    width: space(l);
  }
}
