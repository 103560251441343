.intro-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.4s 0.3s ease-in-out, transform 0.4s 0.3s ease-in-out;
  background: color(surface);
  padding-right: 7px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(m) {
    transition: opacity 0.4s 0.3s ease-in-out, transform 0.3s 0.3s ease-in-out;
  }

  &--ended {
    pointer-events: none;
    opacity: 0;
    transform: translateY(-40%);

    @include mq(m) {
      transform: translateY(-20%);
    }
  }

  video {
    width: 200%;
    height: 100%;
    max-width: 1440px;
    object-fit: contain;
    object-position: 50% 15%;

    @include mq(m) {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}
