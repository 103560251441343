@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia-Pro-Regular.woff2') format('woff2'), url('../fonts/Sofia-Pro-Regular.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia-Pro-Regular.woff2') format('woff2'), url('../fonts/Sofia-Pro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia-Pro-Light.woff2') format('woff2'), url('../fonts/Sofia-Pro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia-Pro-Light-Italic.woff2') format('woff2'),
    url('../fonts/Sofia-Pro-Light-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Ultra Standard';
  src: url('../fonts/GT-Ultra-Standard-Regular.woff2') format('woff2'),
    url('../fonts/GT-Ultra-Standard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Ultra Standard';
  src: url('../fonts/GT-Ultra-Standard-Bold.woff2') format('woff2'),
    url('../fonts/GT-Ultra-Standard-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Ultra Standard';
  src: url('../fonts/GT-Ultra-Standard-Thin-Italic.woff2') format('woff2'),
    url('../fonts/GT-Ultra-Standard-Thin-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
