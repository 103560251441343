.contact-form {
  & > div {
    background: color(neutral, 10);
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 5 / -1;
    }

    & > * + * {
      margin-top: space(m);
    }

    form {
      margin-top: space(l);
    }
  }

  [data-alignment='center'] & > div {
    @include mq(m) {
      grid-column: 3 / -3;
    }
  }
}
